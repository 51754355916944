<template>
  <v-dialog v-model="loading" hide-overlay persistent width="500">
    <v-card color="primary" class="text-center overflow-hidden pb-4" dark>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        {{ text }}
      </v-card-text>
      <v-progress-circular
        v-if="progress"
        :size="100"
        :width="7"
        color="blue lighten-5"
        class="mx-auto my-2"
        indeterminate
      ></v-progress-circular>
      <v-btn
        v-if="!progress"
        class="mb-2"
        @click="() => this.$router.push({ name: 'home' })"
      >
        {{ getUFButtonText("backToHomePage") }}
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import { UFButtonText } from "@/utils/literals";

export default {
  name: "LoadingMessageDialog",
  props: {
    loading: {
      required: true,
      type: Boolean,
    },
    progress: {
      required: false,
      type: Boolean,
      default: true,
    },
    text: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
  },
  methods: {
    getUFButtonText(key) {
      return UFButtonText[key] || "";
    },
  },
};
</script>

<style scoped></style>
