export default class WebSocketManager {
  private socket: WebSocket | null = null;
  private retries = 0;
  private readonly maxRetries: number = 3;
  private readonly waitBeforeReconnect: number = 3000;

  constructor(
    private readonly url: string,
    messageListener: CallableFunction,
    onRetryFail?: CallableFunction,
    onErrorHandler?: CallableFunction,
    onOpen?: CallableFunction
  ) {
    this.connect(messageListener, onRetryFail, onErrorHandler, onOpen);
  }

  private connect(
    messageListener: CallableFunction,
    onRetryFail?: CallableFunction,
    onErrorHandler?: CallableFunction,
    onOpen?: CallableFunction
  ): void {
    this.socket = new WebSocket(this.url);

    this.socket.onopen = () => {
      console.log("WebSocket connected to the following endpoint : ", this.url);
      this.retries = 0;
      console.log("chat open callback", onOpen);
      if (onOpen !== undefined) {
        onOpen();
      }
    };

    this.socket.onclose = (event: CloseEvent) => {
      console.log(
        "WebSocket disconnected from the following endpoint : ",
        this.url,
        "\n Reason :",
        event.reason,
        event.code
      );
      if (event.code != 1000 && event.code != 1001) {
        if (this.retries < this.maxRetries) {
          setTimeout(() => {
            console.log("Attempting to reconnect...");
            this.connect(messageListener, onRetryFail, onErrorHandler, onOpen);

            this.retries++;
          }, this.waitBeforeReconnect);
        } else {
          // Alert user and provide a user-friendly message
          onRetryFail ? onRetryFail() : alert("failed to reconnect to");
        }
      } else {
        console.log("Normal ws closed ");
      }
    };

    if (onErrorHandler) {
      this.socket.onerror = (event: Event) => onErrorHandler(event);
    } else {
      this.socket.onerror = (error: Event) => {
        console.error("WebSocket error:", error);
      };
    }

    this.socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      messageListener(data);
    };
  }

  public send(
    data: Record<string, any> | string,
    onError: CallableFunction,
    onSuccess?: CallableFunction
  ): void {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(data));
      if (onSuccess) {
        onSuccess();
      }
    } else {
      console.error("WebSocket connection is not open. Unable to send data.");
      onError();
    }
  }

  public close(code?: number, reason?: string): void {
    if (this.socket) {
      this.socket.close(code, reason);
    }
  }
}
