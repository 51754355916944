
import { UFButtonText, UFDialogText } from "@/utils/literals";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "DisplayEndDialog",
})
export default class DisplayEndDialog extends Vue {
  @Prop({ type: Boolean, required: true })
  display!: boolean;
  @Prop({ type: String, required: true })
  sessionId!: boolean;
  @Prop({ type: Boolean, required: true })
  feedbackEnable!: boolean;

  redirectToFeedback(): void {
    this.$router.push(`/feedback/${this.sessionId}`);
  }
  redirectToHome(): void {
    this.$router.push({
      name: "home",
    });
  }
  getUFButtonText(key: string): string {
    return UFButtonText[key];
  }
  getUFDialogText(key: string): string {
    return UFDialogText[key];
  }
}
