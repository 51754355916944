<template>
  <div class="d-flex flex-row align-center">
    <v-tooltip top color="info" v-if="!hasJoined">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          @click="connect"
          x-small
          dark
          icon
          color="info"
        >
          <v-icon>mdi-arrow-right-drop-circle</v-icon>
        </v-btn>
      </template>
      <span>Rejoindre le partage audio</span>
    </v-tooltip>
    <div v-if="hasJoined" class="d-flex flex-row">
      <div
        class="d-flex flex-row"
        v-for="track in audioTracks"
        :key="`audio-${track.getId()}`"
      >
        <audio :ref="track.getId()" autoplay />
        <!--          <pre>{{ track }}</pre>-->
        <!--          <pre>type : {{ track.getType() }}</pre>-->
        <!--        <pre>isMuted : {{ track.isMuted() }}</pre>-->
        <div v-if="!track.track.label.includes('remote')">
          <v-chip
            class="ma-1"
            small
            :color="isMuted ? 'warning' : 'success'"
            text-color="white"
          >
            Connecté au chat audio {{ isMuted ? "- muet" : "" }}
          </v-chip>
          <v-tooltip top v-if="!isMuted" color="warning">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="onMute(track)"
                color="warning"
                icon
                small
                v-on="on"
                v-bind="attrs"
              >
                <v-icon small>mdi-microphone-off</v-icon>
              </v-btn> </template
            ><span>Couper le micro</span>
          </v-tooltip>
          <v-tooltip top v-if="isMuted" color="success">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="onUnMute(track)"
                color="success"
                icon
                small
                v-on="on"
                v-bind="attrs"
              >
                <v-icon small>mdi-microphone</v-icon>
              </v-btn>
            </template>
            <span>Débloquer le micro</span>
          </v-tooltip>
          <v-tooltip top color="error">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="disconnect"
                icon
                x-small
                dark
                color="error"
                v-on="on"
                v-bind="attrs"
              >
                <v-icon>mdi-exit-run</v-icon>
              </v-btn>
            </template>
            <span>Quitter le partage audio</span>
          </v-tooltip>
        </div>
        <!--          <pre>id : {{ track.getId() }}</pre>-->
        <!--        <pre>deviceId : {{ track.getDeviceId() }}</pre>-->
        <!--        <pre>isEnded : {{ track.isEnded() }}</pre>-->
        <!--          <pre>{{ track }}</pre>-->
      </div>
    </div>
  </div>
</template>

<script>
import options from "@/config/jitsi.js";

export default {
  name: "Jitsi",
  prop: {
    sessionId: { required: true, type: String },
  },
  data() {
    return {
      connection: null,
      room: null,
      hasJoined: false,
      muted: false,
      videoTracks: [],
      audioTracks: [],
    };
  },
  computed: {
    isMuted: {
      get() {
        return this.muted;
      },
      set(muted) {
        this.muted = muted;
      },
    },
  },
  methods: {
    addTrack(track) {
      if (track.getType() === "video") {
        this.videoTracks.push(track);
      } else if (track.getType() === "audio") {
        this.audioTracks.push(track);
      }
      this.$nextTick().then(() => {
        track.attach(this.$refs[track.getId()][0]);
      });
    },
    removeTrack(track) {
      if (track.getType() === "video") {
        this.videoTracks = this.videoTracks.filter(
          (t) => t.getId() !== track.getId()
        );
      } else if (track.getType() === "audio") {
        this.audioTracks = this.audioTracks.filter(
          (t) => t.getId !== track.getId()
        );
      }
      //  detach(container) - removes the track from the container.
    },
    openRoom() {
      if (window.JitsiMeetJS) {
        this.connect();
      } else alert("Jitsi Meet API script not loaded");
    },
    connect() {
      const roomName = `eccipe-${this.sessionId}`;
      window.JitsiMeetJS.init();
      window.JitsiMeetJS.setLogLevel(window.JitsiMeetJS.logLevels.WARN);

      let appID = null;
      let token = null;

      this.connection = new window.JitsiMeetJS.JitsiConnection(
        appID,
        token,
        options
      );

      this.connection.addEventListener(
        window.JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
        this.onConnectionSuccess
      );
      this.connection.addEventListener(
        window.JitsiMeetJS.events.connection.CONNECTION_FAILED,
        this.onConnectionFailed
      );
      this.connection.addEventListener(
        window.JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
        this.disconnect
      );
      this.connection.connect();
    },
    onConnectionSuccess() {
      console.log("connection success");
      const roomName = "eccipe-127fed30-d4ac-4086-b274-ea0328b51ab9";
      this.room = this.connection.initJitsiConference(roomName, {
        startWithAudioMuted: true,
        startAudioOnly: true,
        // ignoreStartMuted: false,
        // startSilent: true,
      });
      console.log("is start muted");
      console.log(this.room.isStartAudioMuted());
      this.room.on(
        window.JitsiMeetJS.events.conference.TRACK_ADDED,
        this.addTrack
      );
      this.room.on(
        window.JitsiMeetJS.events.conference.TRACK_REMOVED,
        this.removeTrack
      );
      this.room.on(
        window.JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED,
        this.onMuteChange
      );
      this.room.on(
        window.JitsiMeetJS.events.conference.USER_JOINED,
        this.onUserJoined
      );
      this.room.on(
        window.JitsiMeetJS.events.conference.USER_LEFT,
        this.onUserLeft
      );
      this.room.on(
        window.JitsiMeetJS.events.conference.CONFERENCE_LEFT,
        this.onConferenceLeft
      );
      this.room.setStartMutedPolicy({ audio: true, video: true });
      console.log("is start muted");
      console.log(this.room.isStartAudioMuted());
      console.log(this.room.getStartMutedPolicy());
      this.room.on(
        window.JitsiMeetJS.events.conference.CONFERENCE_JOINED,
        this.onConferenceJoined
      );
      this.room.on(window.JitsiMeetJS.events.conference.STARTED_MUTED, () =>
        console.log("start muted")
      );
      this.room.join();
    },
    onMute(track) {
      track.mute();
      this.isMuted = true;
      console.log(`Track ${track.getId()} has been muted.`);
    },
    onMuteChange(track) {
      console.log(`Track ${track.getId()} has been muted/unmuted.`);
    },
    onUnMute(track) {
      track.unmute();
      this.isMuted = false;
      console.log(`Track ${track.getId()} has been unmuted.`);
    },
    onUserJoined(
      id, // string
      user // JitsiParticipant
    ) {
      console.log(`User ${id} joined.`);
    },
    onUserLeft(
      id, // string
      user // JitsiParticipant
    ) {
      console.log(`User ${id} left has been muted.`);
    },
    onConferenceLeft() {
      console.log("You left the conference");
    },
    onConferenceJoined() {
      console.log("conference joined!");
      this.isJoined = true;
      window.JitsiMeetJS.createLocalTracks({
        devices: ["audio"],
      })
        .then((tracks) => {
          tracks.forEach((track) => {
            track.mute();
            this.isMuted = true;
            this.room.addTrack(track);
          });
        })
        .catch((error) => {
          console.error("There was an error creating the local tracks:", error);
        });

      this.hasJoined = true;
    },
    onRemoteTrack(track) {
      this.addTrack(track);
    },
    onConnectionFailed() {
      console.log("connection failed");
    },
    disconnect() {
      this.hasJoined = false;
      let myVideos = this.videoTracks.filter(
        (track) => !track.track.label.includes("remote")
      );
      myVideos.forEach((video) => video.dispose());
      let myAudios = this.audioTracks.filter(
        (track) => !track.track.label.includes("remote")
      );
      myAudios.forEach((audio) => audio.dispose());
      if (this.room) {
        this.room
          .leave()
          .then()
          .catch((error) => console.log(error.message));
        this.room = null;
      }
      if (this.connection) {
        this.connection.disconnect();
        this.connection = null;
      }
      this.audioTracks = [];
    },
    beforeDestroy() {
      this.disconnect();
    },
  },
};
</script>

<style scoped>
.video {
  /*width: 100%;*/
  width: 10%;
  max-width: inherit;
}
</style>
