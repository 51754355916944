
import { Vue, Component, Prop } from "vue-property-decorator";
import { IMessage } from "@/schemas/IMessage";
import { mapState } from "vuex";

@Component({
  name: "ChatBubble",
  computed: {
    ...mapState("user", {
      currentUserId: "userId",
    }),
    ...mapState("session", ["session", "users"]),
  },
})
export default class ChatBubble extends Vue {
  @Prop({ required: true })
  message!: IMessage;

  // @Prop({ type: String, required: true })
  // currentUserId!: string;

  @Prop({ type: String, required: true })
  username!: string;

  @Prop({ type: String, required: true })
  color!: string;
}
